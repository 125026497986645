<template>
<div>
  <!-- <b-card no-body>
    <b-card-header>
      <b-card-title></b-card-title> -->
      <!-- dropdown -->
      <!-- <b-dropdown
        dropright
        variant="link"
        toggle-class="p-0"
        no-caret
      >
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="MoreVerticalIcon"
          />
        </template>
        <b-dropdown-item>
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item>
          Last Month
        </b-dropdown-item>
        <b-dropdown-item>
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
      <!--/ dropdown -->
    <!-- </b-card-header> -->

    <!-- chart -->
    <!-- <b-card-body> -->
      <PolarAreaChartComponent
        :height="250"
        :data="chartjsData.data"
        :options="chartjsData.options"
      />
    <!-- </b-card-body>
  </b-card> -->
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BCardHeader, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import PolarAreaChartComponent from './PolarAreaChartComponent.vue'


export default {
  components: {
    PolarAreaChartComponent,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BDropdown,
    BDropdownItem,
  },
  props: {
    chartjsData: {}
  },
  data() {
    return {
    }
  },
}
</script>
