<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          Analisis Servicios x Categoria
        </b-card-title>
        <b-card-sub-title>Estadistica de Servicios x Categoria Día a Día</b-card-sub-title>
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <LineChartComponent
        :height="250"
        :data="chartjsData.data"
        :options="chartjsData.options"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import LineChartComponent from './LineChartComponent.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    LineChartComponent,
  },
  data() {
    return {
      
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  props: {
    chartjsData: {}
  }
}
</script>
